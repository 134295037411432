// for bootstrap tabbings that contain the basic js-scroll-slider
// problem: the slider is not initialized when the tabbing is initialized and the slider is on a hidden page

import * as scrollSlider from '@elements/scroll-slider';
import { onFind } from "@elements/init-modules-in-scope"
import { findAll, on } from "@elements/dom-utils"

export function init() {
    onFind(".js-tabbing-slider-nav-item", (toggle) => {
        on('shown.bs.tab', () => {
            const allSliders = findAll('.js-scroll-slider')
            const allUninitializedSliders = [...allSliders].filter(slider => (
                !slider.querySelector('.js-scroll-slider__item.is-active')
            ))

            // important to only initialize uninitialized sliders, because otherwise the sliders will jump to the first slide again
            allUninitializedSliders.forEach((elem) => {
                scrollSlider.createScrollSlider(
                    elem, {
                        arrowIconClass: 'icon icon-arrow-right'
                    }
                );
            })
        }, toggle)
    })
}
