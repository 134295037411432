"use strict";
import {on, find, findAll, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import Tab from 'bootstrap/js/dist/tab';
export function init() {
    onFind('[data-bs-toggle="tab"]', function (element) {
        let hash = window.location.hash;

        if(hash !== ""){
            let tab = find('[href="' + hash + '"]');

            if(!hasClass('active', tab)){
                Tab.getInstance(tab).show();
            }
        }
    });
}