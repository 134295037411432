import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on} from "@elements/dom-utils";

export function init() {
    onFind('.js-cookieconsent-overlay', (overlay) => {
        let consentAcceptButton = findIn('.js-consent__accept-btn', overlay);
        on('click', () => {
            Cookiebot.submitCustomConsent(true, true, true);
            setTimeout(function () {
                window.location.reload(true);
            }, 1000);
        }, consentAcceptButton)
    })
}