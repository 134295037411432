import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as tabbingExtension from './scripts/tabbing-extension';
tabbingExtension.init();

import * as dynamicTabbing from './scripts/dynamic-tabbing';
dynamicTabbing.init();

import * as tabbingAnimations from './scripts/tabbing-animations';
tabbingAnimations.init();

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrowIconClass: 'icon icon-arrow-right'
});

import * as tabbingSlider from "./scripts/tabbing-slider"
tabbingSlider.init()

import * as mixedTeaserScrollSlider from '@elements/scroll-slider';
mixedTeaserScrollSlider.init({
        dots: true,
        arrowIconClass: 'icon icon-arrow-right',
    },{
        base: '.js-scroll-slider-teaser',
        area: '.js-scroll-slider-teaser__area',
        item: '.js-scroll-slider-teaser__item',
        dotsContainer: '.js-scroll-slider-teaser__dots'
    }
);
onFind(".js-scroll-slider-teaser", function (element) {
    element.classList.add("scroll-slider-no-spacing-left") // WIP, to apply different slider styling only for content pages
})

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as countdown from '@elements/countdown';
countdown.init({
    prependZero: true
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-bs-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as imgTextSlider from './scripts/img-text-slider';
imgTextSlider.init();

import * as hotspotPopup from './scripts/hotspot-popup';
hotspotPopup.init();

import * as teaserAnimation from './scripts/teaser-animation';
teaserAnimation.init();

import * as parallax from './scripts/parallax';
parallax.init();

import * as cookieConsent from './scripts/cookie-consent-overlay';
cookieConsent.init();