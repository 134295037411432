import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {removeClass,on,addClass, findAllIn, findIn} from '@elements/dom-utils';

const selectors = {
    base: '.js-img-text-slider',
    items: '.js-img-text-slider__item',
    bigImg: '.js-img-text-slider__big-img',
    text: '.js-img-text-slider__text',
    title: '.js-img-text-slider__title',
    link: '.js-img-text-slider__link'
};
const isMobile = matchMedia('(max-width: 767px)').matches || false;
export function init() {
    onFind(selectors.base, function (baseElement) {
        let onSlide = false;
        let blazeItems = findAllIn(selectors.items, baseElement);

        const blazeSlider = new BlazeSlider(baseElement, {
            all: {
                slidesToScroll: 1,
                slideGap:  isMobile ? '8px' : '20px',
                slidesToShow: isMobile ? 1 : 3,
                draggable: true,
                transitionDuration:400,
                transitionTimingFunction: 'cubic-bezier(0.6, 0, 0.5, 1)',
            },
        });

        let track = findIn('.blaze-track', baseElement);
        let observer = new MutationObserver(function(mutations) {
            if(!onSlide) {
                mutations.forEach(function(mutationRecord) {
                    if(!isMobile) {
                        let bigImg = findIn(selectors.bigImg, baseElement);
                        addClass('blaze-animation', bigImg);
                    }
                    let content = findIn('.img-text-slider__content', baseElement);
                    addClass('blaze-content-animation', content);
                });
            }
        });
        observer.observe(track, { attributes : true, attributeFilter : ['style'] });

        blazeSlider.onSlide((page, firstSlideIndex, lastSlideIndex) => {
            onSlide = true;

            blazeItems.map((item) => removeClass('blaze-active', item));
            let currentSlide = blazeSlider.slides[0];
            currentSlide.classList.add("blaze-active");

            if(!isMobile) {
                let bigImgPath = currentSlide.dataset.imgTextSliderBigImg;
                let bigImg = findIn(selectors.bigImg, baseElement);
                bigImg.style.setProperty('--image-src', "url(" + bigImgPath + ")");
                removeClass('blaze-animation', bigImg);
            }

            let textContent = JSON.parse(currentSlide.dataset.imgTextSliderText);
            let text = findIn(selectors.text, baseElement);
            let title = findIn(selectors.title, baseElement);
            let link = findIn(selectors.link, baseElement);

            if(textContent.text){text.innerHTML  = textContent.text;}
            if(textContent.title){title.innerHTML  = textContent.title;}
            if(textContent.link.text){link.innerHTML = textContent.link.text;}
            if(textContent.link.href){link.href = textContent.link.href;}

            let content = findIn('.img-text-slider__content', baseElement);
            removeClass('blaze-content-animation', content);

            setTimeout(function() {
                onSlide = false;
            }, 100);
        });
    });
}

